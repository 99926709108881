"use client"

import type { AppProps } from "next/app"
import type { FunctionComponent, PropsWithChildren } from "react"

import { createContext, useState } from "react"

import { Translations } from "@/modules/i18n/translations"
import { useLocale } from "@/modules/locales/useLocale"

export const TranslationContext = createContext<Translations | null>(null)

type TranslationProviderProps = PropsWithChildren<{
  pageProps?: AppProps["pageProps"]
}>

export const TranslationProvider: FunctionComponent<TranslationProviderProps> = ({ children, pageProps }) => {
  const [translations] = useState(() => new Translations())
  const locale = useLocale()

  if (pageProps && pageProps.translations) {
    translations.add(locale, pageProps.translations)
  }

  return <TranslationContext.Provider value={translations}>{children}</TranslationContext.Provider>
}
